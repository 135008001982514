import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import { useLocation, useNavigate } from "react-router-dom";

export default function NotFound() {
  const locat = useLocation();

  const navigate = useNavigate();
  useEffect(() => {
    navigate("/");
  }, [locat.pathname]);

  return <div className="showPage"></div>;
}
