import { React, useState, useEffect } from "react";
import Footer2 from "./Footer2";

import "./Button.css";
import { Icon } from "@iconify/react";
import { useNavigate } from "react-router-dom";

import { Helmet } from "react-helmet";

function TestOurTech_thankyou(props) {
  const TITLE = process.env.REACT_APP_API_SITE_TITLE + " | Thank You";

  // const [signoutTime, setSignoutTime] = useState(180000);
  const [signoutTime, setSignoutTime] = useState(900000);
  const navigate = useNavigate();

  useEffect(() => {
    function timeout() {
      setTimeout(function () {
        localStorage.removeItem("isRegister");
        window.location.replace("/");
        return false;
      }, signoutTime);
    }

    timeout();
  }, [signoutTime]);

  useEffect(() => {
    //FIXME
    const isRegister = localStorage.getItem("isRegister");
    if (
      isRegister === "false" ||
      isRegister === null ||
      isRegister === undefined
    ) {
      navigate("/");
    }
  }, []);
  const myDestory = () => {
    localStorage.removeItem("isRegister");

    navigate("/");
  };

  return (
    <>
      <Helmet>
        <title>{TITLE}</title>
      </Helmet>

      <div className="container-fluid">
        <div className="white-box thank">
          <h1 className="text-center">Thank You! </h1>

          <Icon icon="zmdi:assignment-check" />

          <button
            onClick={() => myDestory()}
            id="Thanku_Buttons"
            className="btn btn-md btn-primary2"
          >
            Go Back
          </button>
        </div>
      </div>
      <Footer2 />
    </>
  );
}
export default TestOurTech_thankyou;
